import ShoppingCartService from '../../../../shared/services/shopping_cart_service.js'
import OrderDetailContent from '../../components/order/detail-content.vue'

export default {
  name: 'search-order-page-container',

  components: {
    ShoppingCartService,
    OrderDetailContent
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      cartService: undefined,
      email: undefined,
      number: undefined,
      orderToken: undefined,
      jwtToken: undefined
    }
  },

  computed: {
    isFilled() {
      return !!this.email && !!this.number
    },

    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    isOrderLoaded() {
      return typeof this.orderToken === 'string'
    },

    order() {
      return this.$store.getters['orders/find'](this.orderToken)
    },

    cartItems() {
      if (this.order.items) {
        return this.order.items.map((item) =>
          this.$store.getters['orderItems/find'](item.id)
        )
      } else {
        return []
      }
    },

    giftItems() {
      if (this.order.gift_items) {
        return this.order.gift_items.map((item) =>
          this.$store.getters['orderItems/find'](item.id)
        )
      } else {
        return []
      }
    }
  },

  created() {
    this.cartService = new ShoppingCartService(this.$vlf, this.$store)
  },

  // mounted() {},

  methods: {
    /**
     * Handles the email address by converting it to lowercase.
     *
     * @param {none} None - This function does not accept any parameters.
     * @return {none} None - This function does not return any value.
     */
    emailAddressHandler() {
      this.email = this.email.toLowerCase()
    },

    searchOrder() {
      if (!this.isFilled) return

      this.$store
        .dispatch('users/searchOrder', {
          email: this.email,
          orderNumber: this.number
        })
        .then((response) => {
          this.orderToken = response.data.data.id
          this.jwtToken = response.data.meta.token
        })
    }
  }
}
