import CurrencyConfigForm from '@applicationComponents/common/currency-config-form.vue'
import { CURRENCY_REFERENCE_CONFIG_KEY } from '@services/currency_reference_service.js'

const MOBILE_BREAK_POINT = 769

export default {
  name: 'site-footer-container',

  // components: {},

  // mixins: [],

  // props: {},

  data() {
    return {
      windowWidth: 0,
      firstOpened: false,
      secondOpened: false,
      thirdOpened: false
    }
  },

  computed: {
    logo() {
      return this.siteCustomDisplayConfig.footer_logo_url
    },

    mobileLogo() {
      return this.siteCustomDisplayConfig.footer_logo_mobile_url
    },

    isMobile() {
      return (
        (this.$store.getters['windowWidth'] || window.innerWidth) <
        MOBILE_BREAK_POINT
      )
    },

    categories() {
      return this.$store.getters['productCategories/allTopLevel'].filter(
        (category) => !category.is_age_restricted
      )
    },

    siteCustomDisplayConfig() {
      return this.$store.getters['siteConfigs/siteCustomDisplayConfig']
    },

    linkData() {
      return this.siteCustomDisplayConfig.footer_settings || {}
    },

    currencyFeatureConfig() {
      return this.siteCustomDisplayConfig.currency_feature_config || {}
    },

    brandIntroLinks() {
      return this.linkData.brand_introduction || []
    },

    memberCenterLinks() {
      return this.linkData.member_center || []
    }
  },

  created() {
    if (this.categories.length === 0)
      this.$store.dispatch('productCategories/all', {
        sort: 'position'
      })

    this.__forceSelectCurrency()
  },

  methods: {
    goWithCategory(category) {
      Turbolinks.visit(`/product-categories/${category.slug}/products`)
    },

    toggleList(target) {
      this[target] = !this[target]
    },

    visitLink(link) {
      let href = link.href

      if (!link.is_target_blank)
        return href === '/#contact'
          ? (window.location = href)
          : Turbolinks.visit(href)

      let isInSiteLink = `${href}`[0] === '/'

      window.open(href, '_blank', `${isInSiteLink ? '' : 'noopener'}`)
    },

    openCurrencyConfigForm() {
      this.$buefy.modal.open({
        parent: this,
        component: CurrencyConfigForm,
        width: 320
      })
    },

    async __forceSelectCurrency() {
      if (!this.currencyFeatureConfig.enabled) return
      if (!this.currencyFeatureConfig.force_select) return
      const currentConfig = await this.$vlf.getItem(
        CURRENCY_REFERENCE_CONFIG_KEY
      )

      if (!currentConfig) this.openCurrencyConfigForm()
    }
  }
}
