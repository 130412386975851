import productImpressionMixin from '../../components/mixins/product_impression_mixin.js'
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import ProductCard from '../../components/product/card.vue'
import DistributionBaseList from '../../components/distribution_base/list.vue'
import OddPagination from '../../components/common/odd-pagination.vue'
import BannerSlideshow from '../../components/brand/slideshow.vue'
import Brand from '../../../../shared/resource_models/brand.js'
import generateGa4Events from '@services/generate_ga4_events'

export default {
  name: 'brand-show-page-container',

  components: {
    ProductCard,
    DistributionBaseList,
    OddPagination,
    BannerSlideshow
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin, productImpressionMixin],

  // props: {},

  data() {
    return {
      currentTab: 1,
      tabs: ['description', 'products', 'distribution_bases'],
      brand: null,
      resourceType: 'products',
      pageSize: 16,
      sortField: 'created_at',
      availableFilters: [],
      isInitializing: true
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['brands/isLoading']
    },

    products() {
      return this.$store.getters['products/all']
    },

    distributionBases() {
      return this.$store.getters['distributionBases/all']
    },

    additionalOptions() {
      return {
        tab: this.currentTab
      }
    },

    listContainerOffsetTop() {
      return this.$el.offsetParent.offsetTop - 50 - 90
    },

    listLocation() {
      return `Brand - ${this.products[0].brand_name}`
    }
  },

  // created() {},

  async mounted() {
    this.brand = new Brand({
      id: this.$el.dataset.brand
    })
    this.fetchingInitialData()
    await this.$store.dispatch('brands/fetchDistributionBases', {
      model: this.brand,
      options: {
        sort: 'location_id'
      }
    })
    this.isInitializing = false
    await this.tryRestoreScrollPosition()
  },

  methods: {
    checkAdditionalOptionsFromUrl() {
      return {
        tab: this.currentQueryString['tab'] || this.currentTab
      }
    },

    updateQueryOptionsForAdditionalOptions(options) {
      this.currentTab = parseInt(options.tab)
    },

    additionalOptionsToQueryString(options) {
      let result = ''

      result += `&tab=${options.tab}`

      return result
    },

    tabChangeHandler() {
      this.updateQueryString(this.currentOptions)
    },

    fetchData(options) {
      this.currentPage = options.pageNumber
      this.$store
        .dispatch(`brands/fetchProducts`, {
          model: this.brand,
          options
        })
        .then(() => {
          this.$store.dispatch('ga4Operation', [
            generateGa4Events('view_item_list', {
              item_list_name: this.listLocation,
              items: this.products
            })
          ])
        })
    }
  }
}
