<template lang="pug">
section.vc-product-variants-selector(v-if="selectedVariant")
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )

  .content-wrapper
    //- HEADER
    .content-header
      .picture-box
        img.picture(:src="displayPicture")
      .product-info
        h1.name {{ product.name }}
        a.brand(:href="brandLink")
          | {{ product.brand_name }}

    .spec-description(v-if="isVariantsLoaded && !!selectedVariant.description")
      .inner-wrapper(v-html="simpleFormat(selectedVariant.description)")

    //- BODY
    .content-body(v-if="hasSelectableVariants")
      template(v-if="product.is_allow_backorder")
        .variants-list.has-message
        .label {{ actionLocaleText('select_x', { x: modelNameLocaleText('product/variant') }) }}
        .variant-unit(
          v-if="isVariantsLoaded"
          v-for="variant in variants"
          :key="variant.id"
          :class="variantSelectedClass(variant)"
          @click="selectVariant(variant)"
        )
          .message
            template(v-if="variant.is_allow_backorder")
              span.allow-backorder {{ attributeLocaleText('product/variant', 'is_allow_backorder') }}
            template(v-else)
              span.sold-out(v-if="isVariantSoldOut(variant)") {{ variantStockStatus(variant) }}
          .name {{ variant.name }}
          .icon
            i.fa.fa-circle-o
      template(v-else)
        .variants-list(
          :class="{ 'has-message': variants.some((variant) => variant.stock < 1) }"
        )
          .label {{ actionLocaleText('select_x', { x: modelNameLocaleText('product/variant') }) }}
          .variant-unit(
            v-if="isVariantsLoaded"
            v-for="variant in variants"
            :key="variant.id"
            :class="variantSelectedClass(variant)"
            @click="selectVariant(variant)"
          )
            .message
              span.sold-out(v-if="isVariantSoldOut(variant)") {{ variantStockStatus(variant) }}
            .name {{ variant.name }}
            .icon
              i.fa.fa-circle-o

    //- FOOTER
    .content-footer
      quantity-picker(
        v-if="isVariantsLoaded"
        :quantity.sync="quantity"
        :current-stock="currentStock"
        :is-allow-backorder="selectedVariant.is_allow_backorder"
      )
      .info-wrapper
        .info-unit.price
          .label {{ attributeLocaleText('product', 'price') }}
          .price
            span.type(:data-currency="currentCurrency") {{ toMoney(selectedVariant.original_price).format() }}
            span.type(:data-currency="currentCurrency") {{ variantShipPrice.format() }}
        .info-unit.price
          .label {{ attributeLocaleText('product', 'sub_total') }}
          .price
            span.type(:data-currency="currentCurrency") {{ subTotal.format() }}

      .action-bar
        template(v-if="isPurchasable")
          .button.is-odd.is-outlined(@click="buyNow") {{ actionLocaleText('buy_now') }}
          .button.is-odd(
            @click="addToCart"
            :class="{ 'is-loading': isProcessing }"
          ) {{ actionLocaleText('add_to_cart') }}
        template(v-else)
          .button.is-light(
            :class="{ 'is-loading': isLoading }"
            disabled
          ) {{ copyLocaleText('stock_status.no_stock') }}
</template>

<script>
import productVariantSelectableMixin from '../mixins/product_variant_selectable_mixin.js'
import promoterEventVariantMixin from '../mixins/promoter_event_variant_mixin.js'

export default {
  // components: {},

  mixins: [productVariantSelectableMixin, promoterEventVariantMixin],

  // props: {},

  data() {
    return {
      isProcessing: false
    }
  },

  computed: {
    brandLink() {
      return `/brands/${this.product.brand_id}`
    },

    variants() {
      return this.$store.getters['productVariants/forProduct'](this.product.id)
    },

    selectedVariant() {
      if (this.isVariantsLoaded) {
        return this.variants.find(
          (variant) => variant.id === this.selectedVariantId
        )
      } else {
        return this.product
      }
    },

    subTotal() {
      return this.variantShipPrice.multiply(this.quantity)
    },

    displayPicture() {
      if (this.isVariantsLoaded) return this.selectedVariant.displayPicture()
    },

    hasSelectableVariants() {
      return this.variants.length > 1
    },

    isLoading() {
      return this.$store.getters['promoterEvents/isLoading']
    },

    requestBody() {
      return {
        data: {
          type: 'order_items',
          attributes: {
            variant_id: this.selectedEventVariantShip.variant_id,
            promoter_share_variant_ship_id: parseInt(
              this.selectedShareVariantShip.id
            ),
            quantity: this.quantity
          }
        }
      }
    },

    _variantShipCompareTarget() {
      return this.selectedVariant
    }
  },
  // created() {},
  async mounted() {
    if (this.variants.length === 0) {
      await this.$store.dispatch('promoterEvents/findProduct', {
        model: this.promoterEvent,
        productId: this.product.id
      })
    }

    this.selectedVariantId = this.masterVariant.id

    this.setMaxHeightToContentBody()

    this._initializeCartService()
  },

  methods: {
    setMaxHeightToContentBody() {
      const contentBody = this.$el.querySelector('.content-body')

      if (!contentBody) return

      contentBody.style.maxHeight = `calc(100vh
                                     - 160px
                                     - ${
                                       this.$el.querySelector('.content-header')
                                         .scrollHeight
                                     }px
                                     - ${
                                       this.$el.querySelector('.content-footer')
                                         .scrollHeight
                                     }px)`
    },

    variantSelectedClass(variant) {
      return {
        'is-selected': this.selectedVariantId === variant.id
      }
    },

    selectVariant(variant) {
      this.selectedVariantId = variant.id
      this.quantity = 1
    },

    addToCart() {
      this._callAddToCartMethod()
        .then(() => {
          this.isProcessing = false
          this.$parent.close()
        })
        .catch((errors) => {
          this.isProcessing = false
          if (
            errors.response.status === 401 &&
            errors.response.data.code === 'token_expired'
          ) {
            this.$parent.close()
          }
        })
    },

    buyNow() {
      this._callAddToCartMethod()
        .then((_) => {
          this.$parent.close()
          this.$vlf.setItem('redirect-path', window.location.href)
          Turbolinks.visit('/cart')
        })
        .catch((errors) => {
          if (
            errors.response.status === 401 &&
            errors.response.data.code === 'token_expired'
          ) {
            this.$parent.close()
          }
          this.isProcessing = false
        })
    },

    isVariantSoldOut(variant) {
      return variant.stock < 1
    },

    variantStockStatus(variant) {
      return this.copyLocaleText(`stock_status.${variant.stock_status}`)
    }
  }
}
</script>
